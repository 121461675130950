
.answer-detail{
  padding: 0px 20px;
  box-sizing: border-box;
  height: 100%;
  display: flex;
  flex-direction: column;
  .answer-detail-header{
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .answer-detail-table{
    border: 1px solid #EEEEEE;
    box-sizing: border-box;
    width: 100%;
    flex: 1;
    height: 1%;
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    ::v-deep .el-scrollbar__wrap{
      overflow-x: hidden;
    }
    .detail-table-header{
      display: flex;
      background: #F6F6F6;
      height: 60px;
      line-height: 60px;
      box-sizing: border-box;
      .table-header-item{
        width: 25%;
        text-align: center;
        box-sizing: border-box;
        &:first-of-type{
          text-align: left;
          padding-left: 58px;
        }
        span{
          color: #666666;
          font-size: 16px;
        }
      }
    }
    .detail-table-body{
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      .table-body-item{
        display: flex;
        flex-direction: column;
        .body-item-top, .body-item-bottom{
          box-sizing: border-box;
          width: 100%;
          display: flex;
          border-bottom: 1px solid #EEEEEE;
          .item-top-item, .item-bottom-item{
            width: 25%;
            height: 70px;
            line-height: 70px;
            text-align: center;
            box-sizing: border-box;
            &:first-of-type{
              text-align: left;
              padding-left: 26px;
              i{
                cursor: pointer;
                color: #333333;
                font-weight: bold;
              }
              span{
                margin-left: 18px;
              }
            }
            span{
              color: #333333;
              font-size: 16px;
            }
            &.item-bottom-item{
              &:last-of-type{
                span{
                  color: #1CB064;
                  cursor: pointer;
                }
              }
            }
          }
        }
      }
    }
  }
}
